var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-dialog',{staticClass:"work-submit",attrs:{"title":"提交审核","visible":_vm.visible,"before-close":_vm.beforeClose,"width":"45%","close-on-click-modal":false},on:{"update:visible":function($event){_vm.visible=$event}}},[_c('el-form',{ref:"form",staticClass:"work-submit-form",attrs:{"model":_vm.form,"rules":_vm.rules,"label-width":"90px"}},[_c('el-form-item',{attrs:{"label":"名称:","prop":"title"}},[_c('el-input',{attrs:{"placeholder":"请输入名称","maxlength":"50","show-word-limit":""},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.title"}})],1),_c('el-form-item',{attrs:{"label":"描述:","prop":"desc"}},[_c('el-input',{attrs:{"type":"textarea","rows":2,"placeholder":"请输入内容","maxlength":"200","show-word-limit":""},model:{value:(_vm.form.desc),callback:function ($$v) {_vm.$set(_vm.form, "desc", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.desc"}})],1),_c('el-form-item',{attrs:{"label":"精神文明:","prop":"label"}},[_c('el-select',{attrs:{"placeholder":"请选择","multiple":""},model:{value:(_vm.form.label),callback:function ($$v) {_vm.$set(_vm.form, "label", $$v)},expression:"form.label"}},_vm._l((_vm.options),function(item){return _c('el-option',{key:item,attrs:{"label":item,"value":item}})}),1)],1),_c('div',{staticClass:"shiti"},[_c('div',{staticClass:"label"},[_vm._v("实体:")]),_c('div',{staticClass:"right"},_vm._l((_vm.entityList),function(e,i){return _c('el-form-item',{key:i,attrs:{"label":e.f_name + ':',"prop":'thing' + i,"label-width":"60px"}},[_c('el-select',{attrs:{"placeholder":"请选择","filterable":"","multiple":"","remote":"","remote-method":(query) => {
                _vm.remoteMethod(query, e);
              },"loading":_vm.selectLoading},on:{"visible-change":(visi) => {
                _vm.visibleChange(visi, i);
              },"focus":()=>{
              _vm.setData(e)
            }},model:{value:(_vm.form['thing' + i]),callback:function ($$v) {_vm.$set(_vm.form, 'thing' + i, $$v)},expression:"form['thing' + i]"}},_vm._l((e.s_name),function(item){return _c('el-option',{key:item,attrs:{"label":item,"value":item}})}),1)],1)}),1)])],1),_c('div',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"type":"primary","loading":_vm.loading},on:{"click":function($event){return _vm.submitForm('form')}}},[_vm._v("提 交")]),_c('el-button',{on:{"click":_vm.beforeClose}},[_vm._v("取 消")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }